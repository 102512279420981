import React from 'react';
import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  align-items: center;
  & input {
    font-family: SF Pro Display;
  }
`;
