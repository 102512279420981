import styled, { keyframes } from 'styled-components'
import React, { HTMLProps } from 'react';

const Svg = styled.svg.attrs({ 
  version: '1.1', 
  xmlns: 'http://www.w3.org/2000/svg', 
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``;


const rotate = keyframes`
  0% {
        transform: rotate(0)
  }
  100% {
      transform: rotate(360deg)
  }
`

const LoaderSvg = styled(Svg)`
    width: 24px;
    height: 24px;
    cursor: pointer;
    animation: ${rotate} 1s steps(12, end) infinite;
`

export const Loader:React.FC<any & HTMLProps<SVGAElement>> = (props) => {

    return (
      <LoaderSvg viewBox="0 0 46 46" {...props} fill="currentColor">
        <g transform="translate(-587 -1313)">
          <rect fill="currentColor" fillOpacity="1.0" width="4" height="7" rx="2" transform="translate(608 1313)"/>
          <rect fill="currentColor" fillOpacity="0.95" width="4" height="7" rx="2" transform="translate(628.918 1322.769) rotate(60)" />
          <rect fill="currentColor" fillOpacity="0.85" width="4" height="7" rx="2" transform="translate(630.919 1345.768) rotate(120)"/>
          <rect fill="currentColor" fillOpacity="0.75" width="4" height="7" rx="2" transform="translate(619.768 1315.082) rotate(30)"/>
          <rect fill="currentColor" fillOpacity="0.70" width="4" height="7" rx="2" transform="translate(633 1334) rotate(90)"/>
          <rect fill="currentColor" fillOpacity="0.65" width="4" height="7" rx="2" transform="translate(623.232 1354.919) rotate(150)"/>
          <rect fill="currentColor" fillOpacity="0.60" width="4" height="7" rx="2" transform="translate(608 1352)"/>
          <rect fill="currentColor" fillOpacity="0.55" width="4" height="7" rx="2" transform="translate(595.144 1342.269) rotate(60)"/>
          <rect fill="currentColor" fillOpacity="0.50" width="4" height="7" rx="2" transform="translate(597.144 1326.269) rotate(120)"/>
          <rect fill="currentColor" fillOpacity="0.45" width="4" height="7" rx="2" transform="translate(600.268 1348.856) rotate(30)"/>
          <rect fill="currentColor" fillOpacity="0.40" width="4" height="7" rx="2" transform="translate(594 1334) rotate(90)"/>
          <rect fill="currentColor" fillOpacity="0.20" width="4" height="7" rx="2" transform="translate(603.732 1321.144) rotate(150)"/>
        </g>
      </LoaderSvg>
    )

}

export default Loader



