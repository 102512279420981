
import { Button, Grid,  } from "@mui/material"
import {styled} from '@mui/material/styles'

import { NavLink } from "react-router-dom"


const StyledNavLink = styled(NavLink)({
  fontSize: 24,
  lineHeight: 1.2,
  textDecoration: 'none',
  color: '#7c7c7c',
  '&.active': {
    color: '#000',
  }
});


interface AppNavProps {
  onCreate?: () => void;
}

export const AppNav:React.FC<AppNavProps> = ({
  onCreate,
}) => {
  return (
    <Grid container item direction="row" px={0} mb={1.5} xs={12} justifyContent="space-between" alignItems="center">
      <Grid container item xs={8} gap={1}>
        <StyledNavLink to="/promos">
          Промо
        </StyledNavLink>
        <StyledNavLink to="/invites">
          Приглашения
        </StyledNavLink>
      </Grid>
      {onCreate && 
        <Button variant="contained" onClick={onCreate}>
          Создать
        </Button>
      }
    </Grid>
  )
}