import styled from "styled-components";

const HintText = styled.h4`
  font: normal normal normal 22px/30px SF Pro Display;
  margin: 0 0 20px 0;
  &.warning {
    color: ${({theme}) => theme.palette.warning.main};
  }
  ${({ theme }) => theme.down(theme.breakpoints.md)} {
    font: normal normal normal 15px/18px SF Pro Display;

  }

`;

export default HintText;