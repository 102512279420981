import { useForm } from "react-hook-form";
import { IPromo } from "../../types";
import { Box, Button, Drawer, FormControl, InputBase, MenuItem, Select, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { promoStore } from ".";
import { useHistory, useParams } from "react-router";
import { useSnackBar } from "../../context/snackbarCtx";
import { useEffect } from "react";

interface EditModalProps {
  open: boolean;
}

export const EditPromoModal: React.FC<EditModalProps> = observer(({open}) => {
  const {key} = useParams<{key: string}>()
  const history = useHistory();
  const {showSnackBar} = useSnackBar()
  const {register, handleSubmit, formState, watch} = useForm<Partial<IPromo>>({
    defaultValues: promoStore.defaultPromo,
    values: promoStore.currentPromo!,
  },);
  useEffect(() => {
    promoStore.getOne(key, true);

  },[key])

  const onSubmit = handleSubmit( async (data) => {
    try {
      await promoStore.save(data);
      // history.push('/promos');
      showSnackBar(promoStore.currentPromo?.key ? 'Промо-акция отредактирована' : 'Промо-акция создана', 'success');
    } catch (error) {
      showSnackBar("Что-то пошло не так", 'error');
    }
   
  });

  return (
    <Drawer open={open} componentsProps={{backdrop: {style: {opacity: 0}}}} anchor="right" onClose={() => {
      history.goBack();
    }}>
      {promoStore.promoLoading 
        ? null 
        : <Box component={'form'} width={400} gap={1} p={2} display="flex" flexDirection="column" onSubmit={onSubmit}>
          <Typography variant="h5">Создание промо-кампании</Typography>
          <FormControl >
            <label htmlFor="settings[service]">Сервис:</label>
            <Select  {...register("settings.service")} defaultValue={formState.defaultValues?.settings?.service}>
              <MenuItem value="at">АнтиТренинги</MenuItem>
              <MenuItem value="sw">SmartWebinar</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined">
            <label htmlFor="name">Название промо-кампании:</label>
            <TextField required variant="outlined" type="text" {...register('name')} hiddenLabel placeholder={'reklama-at2023'}  />
          </FormControl>
          <FormControl>
            <label htmlFor="key">Ссылка:</label>
            <TextField required hiddenLabel variant="outlined" type="text" {...register('key')} placeholder={'reklama-at2023'}  />
          </FormControl>
          {watch().settings?.service === 'at' && 
            <>
              <FormControl >
                <label htmlFor="settings[plan]">Какой тариф активировать:</label>
                <Select {...register("settings.plan")} defaultValue={formState.defaultValues?.settings?.plan}>
                  <MenuItem value="20">Старт</MenuItem>
                  <MenuItem value="100">Любитель</MenuItem>
                  <MenuItem value="300">Профи</MenuItem>
                  <MenuItem value="1000">Бизнес</MenuItem>
                </Select>
              </FormControl>
              <FormControl >
                <label htmlFor="settings[courses]">Зачислить на курсы (id через запятую):</label>
                <TextField variant="outlined" type="text" {...register('settings.courses')}  />
              </FormControl>
            </>
          }

          {watch().settings?.service === 'sw' && 
            <FormControl >
              <label htmlFor="settings[plan]">Количество смартов:</label>
              <TextField {...register("settings.plan")} defaultValue={formState.defaultValues?.settings?.plan || '100'} />
            </FormControl>
          }
      
          <FormControl>
            <label htmlFor="settings[period]">Продолжительность(мес):</label>
            <TextField required={watch().settings?.service === 'sw' ? false : true} variant="outlined" type="text" {...register('settings.period',{valueAsNumber: true})}  />
          </FormControl>
          <FormControl>
            <label htmlFor="expired">Срок действия:</label>
            <InputBase required type="date" {...register('expired', {valueAsDate: true})} defaultValue={'30-06-2024'}  />
          </FormControl>
          <FormControl>
            <label htmlFor="settings[comment]">Комментарий:</label>
            <TextField variant="outlined" rows={3} {...register('settings.comment')} />
          </FormControl>
          <h3>Настройки письма</h3>
          <FormControl>
            <label htmlFor="settings[emailSettings][email]">Адрес отправителя:</label>
            <TextField required variant="outlined" {...register('settings.emailSettings.email')} type="email"  />
          </FormControl>
          <FormControl>
            <label htmlFor="settings[emailSettings][name]">Имя отправителя:</label>
            <TextField required variant="outlined" {...register('settings.emailSettings.name')} type="text"  />
          </FormControl>
          <FormControl>
            <label htmlFor="settings[emailSettings][subject]">Тема:</label>
            <TextField required variant="outlined" {...register('settings.emailSettings.subject')} type="text"  />
          </FormControl>
          <Button variant="contained" color="primary" type="submit" disabled={promoStore.promoSaving}>Сохранить</Button>
        </Box>
      }
    </Drawer>
  )
});