import { AuthBlock, AuthButton } from '@ui/ui-kit';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled, { ThemeProvider } from 'styled-components';
import { themes } from '../..';
import api from '../../api';
import { AppHeader } from '../../components/core/appHeader';
import HintText from '../../components/core/headerHintText';
import { Container } from '../../components/core/pageContainer';
import { AppConsumer, IErrDict } from '../../types';
import { getLink } from '../../utils';
import InviteStore from '../../store/Invite.store';


const Link = styled.a`
  text-decoration: none;
`

const errDict: IErrDict = {
  'invite_expired': 'Подарочный доступ уже был активирован на E-mail:',
  'invite_released': 'Подарочный доступ уже был активирован на E-mail:'
}

interface IResponseErr {
  service: AppConsumer;
  email: string;
  error: string;
}


export const invitesStore = new InviteStore();

export const InviteIndexPage: React.FC = () => {
  const params = useParams<{key: string}>()
  const [email, setEmail] = useState('random@mail.com');
  const [consumer, setConsumer] = useState<AppConsumer>('at');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    async function getInvite (key: string) {
      try {
        const data = await api.invite.get(key);
        window.location.href = data.link;
        if (consumer === 'at') {
          (window as any).ym(36996780, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
        });
        }
       
      } catch (error: any ) {
        const data: IResponseErr = error.response.data;
        if (data) {
          document.title = 'Ошибка доступа';
          setEmail(data.email);
          setConsumer(data.service);
          if (data.service === 'at') {
            (window as any).ym(36996780, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true
            });
          }
          setError(errDict[data.error]);
          setLoading(false);
        }
      }
    }
    getInvite(params.key);
  }, [params.key]);

  if (loading) return null;

  return (
    <ThemeProvider theme={themes[consumer]}>
      <AppHeader consumer={consumer} />
      <Container>
        <AuthBlock title="Ошибка доступа">
          <HintText>{error}<br/> <b>{email}</b> </HintText>
          <Link href={getLink(consumer)} >
            <AuthButton size="lg" variant="contained">На главную</AuthButton>
          </Link>
        </AuthBlock>
      </Container>
    </ThemeProvider>
  )
}