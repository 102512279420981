import { AppConsumer, IInvite, IPromo } from "../types";
import http from "./_client";
import { IListResponse } from "./types";

export async function getInvite(key: string, full?: boolean): Promise<{link: string}> {
  const response = await http.get<{link: string}>(`/invite/${key}`, {
    params: {full}
  });
  return response.data;
}


export async function get(key: string, full?: boolean): Promise<{link: string, service: AppConsumer}> {
  const response = await http.get<{link: string, service: AppConsumer}>(`/invite/${key}`, {params:{full}});
  return response.data;
}

export async function list(full: boolean = true, page: number = 1):Promise<IListResponse<{invite: IInvite, promo: IPromo}>> {
  const resp= await http.get<IListResponse<{invite: IInvite, promo: IPromo}>>('/invite', {params:{full, pagenum: page}});
  return resp.data;
}

export async function update(promo: IInvite) {
  await http.put(`/promo/${promo.key}`, promo);
}

const invite = {
  get: get,
  list: list,
  update: update
}


export default invite;