import React, { useEffect } from 'react';
import {observer} from 'mobx-react'
import { invitesStore } from '.';
import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import { AppNav } from '../../components/nav';
import { dateTimeFormatter } from '../../utils';
import { useHistory, useLocation} from 'react-router';
import { IInvite } from '../../types';
import { Link } from 'react-router-dom';


const statusDict: {[key: number]: string} = {
  1: "Новый",
  2: "Отправлен",
  3: "Активирован"
}

export const InvitesList: React.FC = observer(() => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    invitesStore.list(true);
  },[]);

  const handleCreate = () => {
    // invitesStore.setDefaultInvite();
    // history.push({pathname: '/promos/new', state: {
    //   editPromo: location
    // }});
  }

  const handleEdit = (invite: IInvite) => {
    history.push({pathname: `/invites/edit/${invite.key}`, state: {
      editPromo: location
    }});
    // invitesStore.getOne(key, true);

    invitesStore.currentInvite = invite;
  }
  return (
    <Grid 
      container 
      item
      direction="column" 
      alignItems="center" 
      justifyContent="center" 
      alignSelf="center"
      md={8}
      mt={8}
    >
      <Grid container item md={12}>
        <AppNav />
      </Grid>
      <Grid item container md={12}>
        <Box mt={2} />
        <TableContainer component={Paper} style={{maxHeight: 'calc(100vh - 125px)'}}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  Ссылка
                </TableCell>
            
                <TableCell>
                  Сервис
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
                <TableCell>
                  Статус
                </TableCell>
                <TableCell>
                  Промо
                </TableCell>
                <TableCell>
                  Создано
                </TableCell>
                <TableCell>
                  Обновлено
                </TableCell>
                <TableCell>
                  Закончится
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {invitesStore.invites.map((invite) => (
              <TableRow key={invite.key}>
                <TableCell>
                  {invite.key}
                </TableCell>
                <TableCell align='center'>
                  {invite.settings.service}
                </TableCell>
                <TableCell>
                  {invite.email}
                </TableCell>
                <TableCell align='center'>
                  {statusDict[invite.status]}
                </TableCell>
                <TableCell>
                  <Link to={{
                    pathname: `/promos/edit/${invite.promo.key}`,
                    state: {
                      editPromo: location
                    }
                  }}>
                    {invite.promo.key}
                  </Link>
                </TableCell>
                <TableCell>
                  {dateTimeFormatter(invite.createdAt)}
                </TableCell>
                <TableCell>
                  {dateTimeFormatter(invite.updatedAt)}
                </TableCell>
                <TableCell>
                  {dateTimeFormatter(invite.expiredAt)}
                </TableCell>
                <TableCell>
                  {/* <Tooltip title="Редактировать">
                    <IconButton onClick={() => handleEdit(invite)} disabled={invite.isReleased || invite.isExpired}>
                      <Edit color={invite.isReleased || invite.isExpired ? "disabled" : "primary"} />
                    </IconButton>
                  </Tooltip> */}
                </TableCell>
              </TableRow>
            ))}
        
            </TableBody>
            <TableFooter style={{position: 'sticky', bottom: 0, backgroundColor: '#fff'}}>
              <TableRow>
                <TablePagination 
                count={invitesStore.pagination.total} 
                page={invitesStore.pagination.pagenum - 1} 
                rowsPerPage={invitesStore.pagination.perpage}
                onPageChange={(_, page) => invitesStore.list(true, page + 1)}
                //@ts-ignore
                rowsPerPageOptions={-1}
                
              />
              </TableRow>
            </TableFooter>
          </Table>
       
        </TableContainer>
      </Grid>
    </Grid>
  )
})