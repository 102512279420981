import { AppConsumer } from "../types";

export function getLink (consumer: AppConsumer) {
  switch (consumer) {
    case 'at':
      return process.env.REACT_APP_AT_LINK
    case 'sw':
    case 'webinar':
      return process.env.REACT_APP_SW_LINK;
    default:
      return 'https://antitreningi.ru'
  }
}


const fullPres = Intl.DateTimeFormat('ru-RU', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',

})

export function dateTimeFormatter(date: string) {
  return fullPres.format(new Date(date));
}