import { makeAutoObservable, runInAction } from "mobx";
import api from "../api";
import { IInvite, IPromo } from "../types";
import { IPagination } from "../types/pagination";

export default class InviteStore {
  
  listLoading: boolean = true;
  inviteLoading: boolean = false;


  defaultInvite: IInvite =  {
    expired: '',
    key: '',
    name: '',
    email: '',
    settings: {
      comment: '',
      period: 1,
      plan: '20',
      service: 'at',
      changePlan: false,
    },
    createdAt: '',
    expiredAt: '',
    isExpired: false,
    isReleased: false,
    status: 1,
    updatedAt: '',
  }

  currentInvite: IInvite | null = null;
  invites: Array<IInvite & {promo: IPromo}> = [];
  promoSaving: boolean = false;
  pagination: IPagination = {
    pagenum: 1,
    perpage: 12,
    total: 0,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setDefaultInvite() {
    this.currentInvite = this.defaultInvite;
  }

  async save(invite: Partial<IInvite>) {
    this.promoSaving = true;
    try { 
      if (this.currentInvite?.key) {
        api.invite.update({...invite, key: this.currentInvite.key} as IInvite)
        return;
      }
      this.promoSaving = false;
      this.list(true);
    } catch (error) {
      this.promoSaving = false;
      
    }
  }


  async list(full: boolean, page: number = 1) {
    runInAction(async () => {
      const res = await api.invite.list(full, page);
      const invites = res.items.map((item) => ({
        ...item.invite,
        promo: item.promo
      }))
      this.invites = invites;
      this.pagination = res.pagination;
    })
  }

  // async getOne(key: string, full?: boolean): Promise<IInvite> {
    // return runInAction(async () => {
    //   this.inviteLoading = true;
    //   try {
    //     const res = await api.invite.get(key, full)
    //     this.currentInvite = res;
    //     this.currentInvite.expired = new Date(this.currentInvite.expiredAt).toISOString().split('T')[0];
    //     this.inviteLoading = false;

    //     return res;
    //   } catch (error) {
    //     this.inviteLoading = false;
    //     throw error;
    //   }
    // })
  // }

  async participate() {
    
  }
}