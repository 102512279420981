import api from "../api";
import { IPromo } from "../types";
import {makeAutoObservable, runInAction} from 'mobx'
import { IPagination } from "../types/pagination";

export default class PromoStore {

  listLoading: boolean = true;
  promoLoading: boolean = false;


  defaultPromo: IPromo =  {
    expired: '',
    key: '',
    name: '',
    settings: {
      comment: '',
      period: 1,
      plan: '20',
      service: 'at',
      emailSettings: {
        email: 'noreply@antitreningi.ru',
        name: '',
        subject: '',
      },
      courses:''
    },
    createdAt: '',
    expiredAt: '',
    isExpired: false,
    status: 1,
    updatedAt: '',
  }

  currentPromo: IPromo | null = null;
  promos: Array<IPromo> = [];
  promoSaving: boolean = false;
  pagination: IPagination = {
    pagenum: 1,
    perpage: 12,
    total: 0,
  }

  constructor() {
    makeAutoObservable(this)
  }

  setDefaultPromo() {
    this.currentPromo = this.defaultPromo;
  }

  async save(promo: Partial<IPromo>) {
    this.promoSaving = true;
    try { 
      if (this.currentPromo?.key) {
        await api.promo.update({...promo, key: this.currentPromo.key} as IPromo)
        return;
      }
      await api.promo.create(promo);
      this.promoSaving = false;
      this.list(true);
    } catch (error) {
      this.promoSaving = false;
      //@ts-ignore
      throw new Error(error);
    }
  }


  async list(full: boolean, page: number = 1) {
    runInAction(async () => {
      const res = await api.promo.list(full, page);
      this.promos = res.items;
      this.pagination = res.pagination;
    })
  }

  async getOne(key: string, full?: boolean) {
    if (!key) return;
    return runInAction(async () => {
      this.promoLoading = true;
      try {
        const res = await api.promo.get(key, full)
        this.currentPromo = res;
        this.currentPromo.expired = new Date(this.currentPromo.expiredAt).toISOString().split('T')[0];
        this.promoLoading = false;

        return res;
      } catch (error) {
        this.promoLoading = false;
        throw error;
      }
    })
  }

  async participate() {
    
  }
}