import React, { useEffect } from 'react';
import {observer} from 'mobx-react'
import { promoStore } from '.';
import { Box, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import { AppNav } from '../../components/nav';
import { dateTimeFormatter } from '../../utils';
import { Edit } from '@mui/icons-material';
import { useHistory, useLocation} from 'react-router';


const statusDict: {[key: number]: string} = {
  1: "Активная",
  2: "Закончилась"
}
export const PromosList: React.FC = observer(() => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    promoStore.list(true);
  },[]);

  const handleCreate = () => {
    promoStore.setDefaultPromo();
    history.push({pathname: '/promos/new', state: {
      editPromo: location
    }});
  }

  const handleEdit = (key: string) => {
    history.push({pathname: `/promos/edit/${key}`, state: {
      editPromo: location
    }});
  }
  return (
    <Grid 
      container 
      item
      direction="column" 
      alignItems="center" 
      justifyContent="center" 
      alignSelf="center"
      md={8}
      mt={8}
    >
      <Grid container item md={12}>
        <AppNav onCreate={() => handleCreate()} />
      </Grid>
      <Grid item container md={12}>
        <Box mt={2} />
        <TableContainer component={Paper} style={{maxHeight: 'calc(100vh - 125px)'}}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  Название
                </TableCell>
                <TableCell>
                  Ссылка
                </TableCell>
                <TableCell>
                  Сервис
                </TableCell>
                <TableCell>
                  Статус
                </TableCell>
                <TableCell>
                  Создано
                </TableCell>
                <TableCell>
                  Обновлено
                </TableCell>
                <TableCell>
                  Закончится
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {promoStore.promos.map((promo) => (
              <TableRow key={promo.key}>
                  <TableCell>
                  {promo.name}
                </TableCell>
                <TableCell>
                  {promo.key}
                </TableCell>
                <TableCell >
                  {promo.settings.service}
                </TableCell>
                <TableCell >
                  {statusDict[promo.status]}
                </TableCell>
                <TableCell>
                  {dateTimeFormatter(promo.createdAt)}
                </TableCell>
                <TableCell>
                  {dateTimeFormatter(promo.updatedAt)}
                </TableCell>
                <TableCell>
                  {dateTimeFormatter(promo.expiredAt)}
                </TableCell>
                <TableCell>
                  <Tooltip title="Редактировать">
                    <IconButton onClick={() => handleEdit(promo.key)} disabled={promo.status === 2}>
                      <Edit color="primary" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        
            </TableBody>
            <TableFooter style={{position: 'sticky', bottom: 0, backgroundColor: '#fff'}}>
              <TableRow>
                <TablePagination 
                count={promoStore.pagination.total} 
                page={promoStore.pagination.pagenum - 1} 
                rowsPerPage={promoStore.pagination.perpage}
                onPageChange={(_, page) => promoStore.list(true, page + 1)}
                //@ts-ignore
                rowsPerPageOptions={-1}
                
              />
              </TableRow>
            </TableFooter>
          </Table>
       
        </TableContainer>
      </Grid>
    </Grid>
  )
})