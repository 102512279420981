import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { authAtTheme, authSwTheme } from '@ui/ui-kit';
import { BrowserRouter } from 'react-router-dom';
import { SnackBarProvider } from './context/snackbarCtx';



export const themes = {
  at: authAtTheme,
  webinar: authSwTheme,
  sw: authSwTheme,
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SnackBarProvider>
        <App />
      </SnackBarProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
