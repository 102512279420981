import React from 'react';
import styled from 'styled-components';
import { AppConsumer } from '../../../types';
import swLogo from '../../../assets/logo/logo-sw.svg';
import swLogoMobile from '../../../assets/logo/logo-sw.svg';
import atLogo from '../../../assets/logo/logo-at.svg';
import atLogoMobile from '../../../assets/logo/logo-at.svg';
import { getLink } from '../../../utils';

const AppHeaderWrap = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 54px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000D;
`

const Link = styled.a`
  text-decoration: none;
`

const Logo = styled.img`
  display: none;
  ${({ theme }) => theme.up(theme.breakpoints.md)} {
    display: inline;
  }

  &.mobile {
    display: inline;
    ${({ theme }) => theme.up(theme.breakpoints.md)} {
      display: none;
    }
  }
  &.sw {
    height: 30px;
    width: auto;
  }
`

interface AppHeaderProps {
  consumer: AppConsumer;
}

export const AppHeader: React.FC<AppHeaderProps> = ({consumer}) => {

  return (
    <AppHeaderWrap>
      {
        consumer === 'at' && 
          <Link href={getLink('at')}><Logo src={atLogo} alt="АНТИТРЕНИГИ" /> <Logo src={atLogoMobile} className="mobile" /></Link>
      }
      {
        consumer === 'webinar' && 
          <Link href={getLink('sw')}><Logo className="sw" src={swLogo} alt="SMARTWEBINAR" /> <Logo src={swLogoMobile} className="mobile" /></Link>
      }
      {
        consumer === 'sw' && 
          <Link href={getLink('sw')}><Logo className="sw" src={swLogo} alt="SMARTWEBINAR" /> <Logo src={swLogoMobile} className="mobile" /></Link>
      }
    </AppHeaderWrap>
  )
}