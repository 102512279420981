import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import {Location} from 'history'
import styled from 'styled-components';
import { PromoIndexPage } from './pages/promo';
import { InviteIndexPage } from './pages/invite';
import { PromosList } from './pages/promo/list';
import { EditPromoModal } from './pages/promo/editModal';
import {configure} from 'mobx';
import { InvitesList } from './pages/invite/list';

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
`;

(window as any).authFormConfig = {};
(window as any).authFormConfig.serviceBasePath = process.env.REACT_APP_AUTH_SERVICEBASEPATH


configure({
  enforceActions: 'never',
  computedRequiresReaction: false,
  reactionRequiresObservable: false,
  observableRequiresReaction: false,
  disableErrorBoundaries: true,
});

const App: React.FC = () => {
  const location = useLocation<{editPromo?: Location}>();
  const editPromo = location.state?.editPromo;
  return (
    <AppContainer>
        <Route path={["/promos/edit/:key", "/promos/new"]}>
          <EditPromoModal open={!!location.state?.editPromo} />
        </Route>
        <Switch location={editPromo || location}>
          <Route path="/promos/" component={PromosList} exact />
          <Route path="/promo/:key" component={PromoIndexPage} exact />
          <Route path="/invites/" component={InvitesList} exact />
          <Route path="/invite/:key" component={InviteIndexPage} />
          <Route path="*">
            <h1>Not Found</h1>
          </Route>
        </Switch>
    </AppContainer>

  );
}

export default App;
