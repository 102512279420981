import { AuthBlock, AuthButton, AuthPhoneInput, AuthTextField } from '@ui/ui-kit';
import { useParams } from 'react-router-dom';
import { IPhoneInputValue } from '@ui/ui-kit/dist/auth/PhoneInput';
import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { themes } from '../..';
import { AppHeader } from '../../components/core/appHeader';
import { AppConsumer, IErrDict } from '../../types';
import { Container } from '../../components/core/pageContainer';
import api from '../../api';
import HintText from '../../components/core/headerHintText';
import { getLink } from '../../utils';
import Loader from '../../components/core/loader';
import PromoStore from '../../store/Promo.store';


const StyledLoader = styled(Loader)`
  color: ${({theme}) => theme.palette.primary.main};
  width: 80px;
  height: 80px;
`

const LoaderBox = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormLoader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 45px;
  padding-bottom: 45px;
`


const errDict: IErrDict = {
  'account_exists': 'На этом e-mail уже есть активный тариф. Укажите другой e-mail',
  'invite_exists':'На e-mail уже был активирован подарок. Укажите другой e-mail',
  'email_req': 'E-mail обязателен',
  'email_bad': 'Неправильный E-mail',
  'phone_req': 'Телефон обязателен',
  'phone_bad': 'Неправильный номер телефона'
}
export const promoStore = new PromoStore();

export const PromoIndexPage: React.FC = () => {
  const params = useParams<{key: string}>();
  const [consumer, setConsumer] = useState<AppConsumer>('sw')
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isExpired, setExpired] = useState(false);
  const [isEmailSent, setEmailSent] = useState('')
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    async function getServiceResultByKey (key: string) {
      try {
        const data = await api.promo.get(params.key);
        setExpired(data.isExpired);
        setConsumer(data.settings.service);
        if (data?.settings.service === 'at') {
          (window as any).ym(36996780, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
        });
        }
        document.title = data.name;
        setLoading(false);
      } catch (error) {
        setError(true);
      }
    }
    getServiceResultByKey(params.key);
  }, [params.key])

  const [phoneObject, setPhoneObject] = useState<IPhoneInputValue>(
    {
      phoneCode: {
        iso: 'ru',
        code: '7'
      },
      phoneNumber: ''
    }
  );
  const [email, setEmail] = useState('');

  const handleSubmit = async () => {
    if (!email || !phoneObject.phoneNumber) {
      return;
    }  
    setFormLoading(true);
    try {
      const data = await api.promo.participate(params.key, {email, phone: `${phoneObject.phoneCode.code}${phoneObject.phoneNumber}`})
      if (data.link) {
        window.location.href = data.link;
      }
      if (data.email) {
        setEmailSent(data.email);
      }
      setFormLoading(false)
    } catch (error: any) {
      const err: string = error.response?.data?.error;
      if (err) {
        if (err.startsWith('email')) {
          setEmailError(errDict[err]);
        }
        if (err.startsWith('phone')) {
          setPhoneError(errDict[err]);
        }
      }
      setFormLoading(false)
    }
  }

  const handleRegClick = () => {
    window.location.href = `${getLink(consumer)}/#/registration`;
  }
  if (error) return null;

  if (loading) return (
    <ThemeProvider theme={themes[consumer]}>
      <LoaderBox>
        <StyledLoader />
      </LoaderBox>
    </ThemeProvider>
  ) ;

  return (
    <ThemeProvider theme={themes[consumer]}>
      <AppHeader consumer={consumer} />
      <Container>
        {
          isEmailSent &&
            <AuthBlock title="Доступ вам отправлен на E-mail">
                <HintText className="warning">Письмо было отправлено на адрес <b>{isEmailSent}</b>.</HintText>
            </AuthBlock>

        }
        {
          !isExpired ?
            <AuthBlock title="Для активации подарка укажите свой E-mail и Телефон">
              { formLoading 
                ? <FormLoader><StyledLoader /></FormLoader> 
                : <React.Fragment>
                    <HintText className="warning">Внимательно проверьте <b>E-mail</b> и <b>Телефон</b>, они нужны для доступа к подаркам.</HintText>
                    <AuthTextField value={email} onChange={e => setEmail(e.currentTarget.value)} label="Ваш e-mail" width="100%" error={emailError} />
                    <AuthPhoneInput
                      // codesAssetsPath={process.env.REACT_APP_AUTH_SERVICEBASEPATH}
                      placeholderCode="Код страны" 
                      placeholderPhone="Телефон"
                      onChange={(phoneObject)=> {
                        const normalizedPhoneObject = {...phoneObject, phoneNumber: phoneObject.phoneNumber.match(/\d/g)?.join('') ?? ''};
                        setPhoneObject(normalizedPhoneObject);
                      }} 
                      value={phoneObject}
                      errorPhoneNumber={phoneError}
                    />
                  </React.Fragment> 
              
              }
              <AuthButton size="lg" variant="contained" disabled={!phoneObject.phoneNumber || !email} onClick={handleSubmit}>Активировать подарок</AuthButton>
            </AuthBlock>:
            <AuthBlock title="Срок возможности активации подарка истёк">
              <HintText>Но вы можете зарегистрироваться на общих основаниях, чтобы размещать свои курсы, обучать клиентов, принимать платежи.</HintText>
              <AuthButton size="lg" variant="contained"  onClick={handleRegClick}>Зарегистрироваться</AuthButton>
            </AuthBlock>
        }
      </Container>
    </ThemeProvider>
  )
}