import { IPromo } from "../types";
import http from "./_client";
import { IListResponse } from "./types";

export async function participate(key: string, formData: any) {
  const response = await http.post<{link?: string; email?: string;}>(`/promo/${key}`, {...formData});
  return response.data;
}

export async function get(key: string, full?: boolean): Promise<IPromo> {
  const response = await http.get<IPromo>(`/promo/${key}`, {params:{full}});
  return response.data;
}

export async function create(data: Partial<IPromo>): Promise<IPromo> {
  const res = await http.post<IPromo>('/promo', data);
  return res.data;
}

export async function list(full: boolean = true, page: number = 1):Promise<IListResponse<IPromo>> {
  const resp= await http.get<IListResponse<IPromo>>('/promo', {params:{full, pagenum: page}});
  return resp.data;
}

export async function update(promo: IPromo) {
  await http.put(`/promo/${promo.key}`, promo);
}
const promo = {
  get: get,
  create: create,
  participate: participate,
  list: list,
  update: update
}

export default promo;